body {
  font-family: 'Staatliches';
}

.App {
  text-align: center;
}

.Snap {
  scroll-snap-align: start;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.jumbotron {
  height: 100vh;
}

.NameJumbo{
  padding-top: 10vmin;
  --body-bg-dark: 'white'
}

.NameRow {
  font-family: 'Staatliches', cursive;
  font-size: 35vmin;
  transition: .5s ease-out;
}

.NameRow > *{
  padding: 0;
}

.NameRow :first-child{
  text-align: right;
}

.NameRow :last-child{
  text-align: left;
}

.NameRow :hover{
  pointer-events: all;
}

.letter-button{
  /* transition: transform 0.3s ease-in-out; */
  display: inline;
  transition: .2s;
  -webkit-user-select: none;
}

.letter-button:hover{
  font-size: 37vmin;
}

.normal-letter:hover{
  color: var(--primary-color);
}

.instagram:hover{
  color: black;
  background: linear-gradient(to top, #FFDC80, #FFDC80, #F77737, #FD1D1D, #C13584, #5B51D8, #5B51D8);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-size: 37vmin;
}

.art:hover{
  color: black;
  background: url('./images/mobility.jpg') no-repeat;
  background-size: 50vmin;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-size: 37vmin;
}

.globe{
  background: url('./images/globe.png') no-repeat;
  -webkit-background-clip: text;
  background-size: 50vmin;
}

.globe:hover{
  -webkit-text-fill-color: transparent;
  font-size: 37vmin;
}

a{
  color: inherit;
  text-decoration: none;
  -webkit-user-select: none;
  cursor: pointer;
}

.about-me{
  font-family: 'Staatliches';
  font-size: 18pt
}

[data-bs-theme=dark]{
  --bs-body-color: white;
}

[data-bs-theme=dark] .carousel-caption{
  color: white !important;
}

[data-bs-theme=dark] .carousel-control-next-icon{
  filter: none !important
}

[data-bs-theme=dark] .carousel-control-prev-icon{
  filter: none !important
}